import React, { useEffect, useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import CLink from "../cLink"
import Image from "../imagev2"
import Arrow from "../vector/arrow"
import tw, { styled } from "twin.macro"
import { useSpring, animated as a } from "@react-spring/web"
import useScroll from "../../helpers/useScroll"
import isClient from "../../helpers/isClient"

//Importamos esto para evitar error que no consigo solucionar
import { Canvas } from "@react-three/fiber"


import TituloEnlace from "./tituloEnlace"
import DescargaPdfPw from "./descargaPdfPw"
import { over } from "lodash"

const CocinasCarrusel = ({ content, pageRuta, lang }) => {
  const [paginaActual, setPaginaActual] = useState(0)
  const [direccion, setDireccion] = useState("izq")
  const [carruselEstado, setCarruselEstado] = useState({
    anchoLI: 1,
    anchoUL: 1,
    numeroPaginas: 1,
    itemsPorPagina: 2,
    altoLITablet: 1,
  })
  const containerRef = useRef()
  const data = useStaticQuery(graphql`
    query cocinasCarruselQuery {
      allSitePage(
        filter: { context: { parent_page: { pwid: { eq: 1024 } } } }
      ) {
        nodes {
          context {
            title
            lang
            texto_corto1
            ruta
            image {
              width
              
              url880
              url440
              url220
              url2000
              url1760
              url220webp
              url440webp
              url880webp
              
              url1760webp
              url2000webp
              url
              height
              description
              base64
              aspectratio
            }
            image_mobile {
              width
              
              url880
              url440
              url220
              url2000
              url1760
              url220webp
              url440webp
              url880webp
              
              url1760webp
              url2000webp
              url
              height
              description
              base64
              aspectratio
            }
          }
        }
      }
      sitePage(context: { pwid: { eq: 1024 } }) {
        context {
          ruta
        }
      }
      processwire {
        config {
          es {
            catalogo_pdf {
              title
              url
            }
          }
          fr {
            catalogo_pdf {
              title
              url
            }
          }
          en {
            catalogo_pdf {
              title
              url
            }
          }
          pt {
            catalogo_pdf {
              title
              url
            }
          }
        }
      }
    }
  `)
  const windowData = useScroll()
  const { windowSize } = windowData
  const esPrimeraPagina = () => {
    return paginaActual === 0
  }
  const esUltimaPagina = () => {
    return paginaActual + 1 >= carruselEstado.numeroPaginas
  }

  const setPaginaMas = () => {
    if (!esUltimaPagina()) {
      setDireccion("izq")
      setPaginaActual(paginaActual + 1)
    }
  }
  const setPaginaMenos = () => {
    if (!esPrimeraPagina()) {
      setDireccion("der")
      setPaginaActual(paginaActual - 1)
    }
  }
  const setPagina = n => {
    if (n < paginaActual) {
      setDireccion("der")
    } else {
      setDireccion("izq")
    }
    setPaginaActual(n)
  }

  const cocinas = data.allSitePage.nodes.filter(n => n.context.lang === lang)
  const nuestrasCocinaUrl = data.sitePage.context.ruta
  const catalogoPdf = data.processwire.config[lang].catalogo_pdf

  const flechaISpring = useSpring({
    transform: esPrimeraPagina() ? "translateX(-100%)" : "translateX(0%)",
    opacity: esPrimeraPagina() ? 0 : 1,
  })
  const flechaDSpring = useSpring({
    transform: esUltimaPagina() ? "translateX(100%)" : "translateX(0%)",
    opacity: esUltimaPagina() ? 0 : 1,
  })

  useEffect(() => {
    const updateEstado = estado => {
      setCarruselEstado(estado)
      if (estado.numeroPaginas - 1 < paginaActual) {
        setPagina(estado.numeroPaginas - 1)
      }
    }
    const containerWidth = isClient
      ? containerRef.current?.getBoundingClientRect().width
      : 768
    const anchoContenedor =
      windowSize.width >= 768 ? containerWidth : windowSize.width
    const itemsPorPagina =
      windowSize.width >= 1200 || windowSize.width < 768
        ? 4
        : windowSize.width >= 1024
        ? 3
        : 2
    const anchoLI = Math.ceil((anchoContenedor - 64 - 64) / itemsPorPagina) //anchiLI incluye el margin de la derecha.
    const altoLITablet = anchoLI
    const anchoUL = 20 + cocinas.length * 114 + 4 + 20 // Util solo para móvil, no para desktop
    const numeroPaginas = Math.ceil(cocinas.length / Math.floor(itemsPorPagina))
    updateEstado({
      anchoLI: anchoLI,
      anchoUL: anchoUL,
      altoLITablet: altoLITablet,
      numeroPaginas: numeroPaginas,
      itemsPorPagina: itemsPorPagina,
    })
  }, [windowSize])
  return (
    <div>
      <div style={{
        height: '1px',
        overflow: 'hidden',
      }}><Canvas /></div>
      {content.data.title && (
        <TituloEnlace
          content={{
            data: {
              checkbox1: false, //fondo oscuro
              title: content.data.title,
              url: nuestrasCocinaUrl,
            },
          }}
        />
      )}
      <div
        css={tw`maxmd:w-100vw maxmd:transform maxmd:-translate-x-1/2 maxmd:relative maxmd:left-1/2`}
      >
        <div
          ref={containerRef}
          css={tw`relative overflow-x-auto md:overflow-x-hidden`}
        >
          <ul
            style={{
              width:
                windowSize.width >= 768
                  ? "auto"
                  : `${carruselEstado.anchoUL * 1.25}px`,
              height: `${
                windowSize.width >= 768
                  ? carruselEstado.altoLITablet - 2
                  : 125 * 1.25 + 12
              }px`,
              paddingLeft: `${windowSize.width >= 768 ? 0 : 20}px`,
              paddingRight: `${windowSize.width >= 768 ? 0 : 20}px`,
            }}
          >
            {cocinas.map((cocinaData, i) => {
              const cocina = cocinaData.context
              if (!cocina.image.base64) {
                return <div>FALTA IMAGEN</div>
              }
              return (
                <Cocina
                  activo={pageRuta === cocina.ruta}
                  key={i}
                  posicion={i}
                  esPrimeraPagina={esPrimeraPagina()}
                  paginaActual={paginaActual}
                  cocina={cocina}
                  number={i}
                  direccion={direccion}
                  windowSize={windowSize}
                  carruselEstado={carruselEstado}
                  numeroCocinas={cocinas.length}
                />
              )
            })}
          </ul>
          <a.div
            onClick={setPaginaMenos}
            css={[
              tw`hidden md:flex absolute w-16 inset-y-0 left-0 items-center justify-center bg-bggrisimage5`,
              !esPrimeraPagina() && tw`cursor-pointer`,
            ]}
            style={flechaISpring}
          >
            <Flecha izquierda={true} />
          </a.div>
          <a.div
            onClick={setPaginaMas}
            css={[
              tw`hidden md:flex absolute w-16 inset-y-0 right-0 items-center justify-center bg-bggrisimage5`,
              !esUltimaPagina() && tw`cursor-pointer`,
            ]}
            style={flechaDSpring}
          >
            <Flecha />
          </a.div>
        </div>
        <div css={tw`hidden md:block mt-3`}>
          {Array(carruselEstado.numeroPaginas)
            .fill(0)
            .map((item, i) => (
              <StyledPagination
                onClick={() => {
                  setPagina(i)
                }}
                key={i}
                activo={paginaActual === i}
              />
            ))}
        </div>
      </div>
      <DescargaPdfPw
        content={{
          data: {
            descarga: catalogoPdf.url,
            title: catalogoPdf.title,
            texto_con_formato1: 'Catalogo',
            showInMobile: true,
          },
        }}
      />
    </div>
  )
}

const Cocina = ({
  posicion,
  numeroCocinas,
  direccion,
  paginaActual,
  esPrimeraPagina,
  cocina,
  windowSize,
  number,
  carruselEstado,
  activo,
}) => {
  const { anchoLI, itemsPorPagina, altoLITablet } = carruselEstado
  const [hover, setHover] = useState(activo)

  const liSpring = useSpring({
    transform:
      windowSize.width < 768
        ? "translateX(0%)"
        : `translateX(${
            (posicion - paginaActual * itemsPorPagina) * anchoLI +
            (esPrimeraPagina ? 0 : 66)
          }px)`,
    width: windowSize.width < 768 ? `${112 * 1.25}px` : `${anchoLI - 2}px`,
    height:
      windowSize.width < 768 ? `${125 * 1.25}px` : `${altoLITablet - 2}px`,
    marginRight: "2px",
    delay:
      windowSize.width < 768
        ? 0
        : direccion === "izq"
        ? posicion * 30
        : (numeroCocinas - posicion - 1) * 30,
  })

  // const transitionSpring = useSpring({
  //     transform: !inView ? "translate(0px,30px)" : "translate(0px,0px)",
  //     opacity: !inView ? 0 : 1,
  //     delay: number * 150,
  // })

  const backgroundSpring = useSpring({
    transform:
      windowSize.width < 768
        ? "scale(1) translate(0%,0%) rotate(0deg)"
        : hover
        ? "scale(1.5) translate(-18%,40%) rotate(-45deg)"
        : `scale(1.5) translate(0%,85%) rotate(${number % 2 ? "0" : "-90"}deg)`,
    delay: hover ? 0 : 0,
  })
  const textSpring = useSpring({
    opacity: windowSize.width < 768 || hover ? 1 : 0,
    delay: hover ? 150 : 0,
  })

  return (
    <a.li
      css={tw`block bg-white float-left md:float-none md:absolute md:top-0 md:left-0 overflow-hidden`}
      style={liSpring}
    >
      <a.div>
        <CLink
          to={cocina.ruta}
          css={tw`block relative`}
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
        >
          <div>
            <Image
              objectFit={"cover"}
              aspectRatioMobile={1.35}
              aspectRatioTablet={1}
              imageTablet={cocina.image}
              imageMobile={cocina.image_mobile}
              widths={["440px", "440px", "850px"]}
              // // aspectRatio={[block.image_mobile.aspectratio, block.image.aspectratio]}
              // alt={cocina.image.description}
              // base64={[cocina.image_mobile.base64,cocina.image.base64]}
              // src={[cocina.image_mobile.url220,cocina.image.url220]}
              // srcSet={[`${cocina.image_mobile.url220} 220w, ${cocina.image_mobile.url440} 440w, ${cocina.image_mobile.url880} 880w, ${cocina.image_mobile.} 980w`,
              // `${cocina.image.url220} 220w, ${cocina.image.url440} 440w, ${cocina.image.url880} 880w, ${cocina.image.} 980w, ${cocina.image.url1760} 1200w, ${cocina.image.url2000} 2000w`]}
              // sizes="(max-width: 768px) 30vw, (max-width: 1024px) 40vw, 20vw"
            />
          </div>
          <a.div
            css={tw`hidden md:block absolute  bg-white w-full inset-0 opacity-100`}
            style={backgroundSpring}
          />
          <a.div
            css={tw`absolute inset-x-0 top-28 md:top-auto md:bottom-0 px-1 md:px-7 pt-1 md:py-4 text-center md:text-left text-marron1`}
            style={textSpring}
          >
            <div
              dangerouslySetInnerHTML={{ __html: cocina.title }}
              css={tw`pb-1 text-obsmall md:text-obmed uppercase font-semibold `}
            />
            { cocina.texto_corto1 && <Subtitle>{cocina.texto_corto1}</Subtitle> }
            <div
              css={tw`-mt-2 md:mt-0 md:pt-4 md:pb-2 transform scale-50 md:scale-100`}
            >
              <Arrow color={"#847360"} />
            </div>
          </a.div>
        </CLink>
      </a.div>
      {activo && <div css={tw`absolute inset-0 bg-bggrisimage5`} />}
    </a.li>
  )
}

const Flecha = ({ izquierda }) => {
  return (
    <svg
      width={13}
      height={22}
      viewBox="0 0 13 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={izquierda ? "M11 1l-10 10L11 21" : "M1 1l11 10L1 21"}
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

// const StyledParrafo = styled.div`
//   ${tw`hidden`};
//   ${tw`md:block`};
//   p {
//     ${tw`text-obsmall2`};
//     ${tw`leading-5`}
//   }
//   a {
//     font-weight: 400;
//     text-decoration: underline;
//   }
// `
const Subtitle = styled.span`
  ${tw`hidden`};
  ${tw`md:block`};
  ${tw`text-obsmall2`};
  ${tw`leading-5`}
  font-weight: 400;
  text-decoration: none;
`
const StyledPagination = styled.span`
  ${tw`block`};
  ${tw`float-left`};
  width: 1rem;
  height: 1rem;
  margin-right: 0.125rem;
  ${tw`cursor-pointer`};
  &::before {
    content: "";
    ${tw`block`};
    width: 1rem;
    height: 0.188rem;
    margin-top: 0.406rem;
    ${props => (props.activo ? tw`bg-gris3` : tw`bg-white`)};
  }
`

export default CocinasCarrusel
